// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-giftexchange-privacy-policy-tsx": () => import("./../../../src/pages/giftexchange/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-giftexchange-privacy-policy-tsx" */),
  "component---src-pages-giftexchange-support-tsx": () => import("./../../../src/pages/giftexchange/support.tsx" /* webpackChunkName: "component---src-pages-giftexchange-support-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-technologies-tsx": () => import("./../../../src/pages/technologies.tsx" /* webpackChunkName: "component---src-pages-technologies-tsx" */),
  "component---src-pages-wineracks-privacy-policy-tsx": () => import("./../../../src/pages/wineracks/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-wineracks-privacy-policy-tsx" */),
  "component---src-pages-wineracks-support-tsx": () => import("./../../../src/pages/wineracks/support.tsx" /* webpackChunkName: "component---src-pages-wineracks-support-tsx" */)
}

